@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  background-color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@layer base {
  :root {
    --color-Primary: 70 66 238;
    --color-Secondary: 0 209 255;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* .pattern:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 215px;
  background: url(./images/wave.svg);
  background-size: cover;
  background-repeat: no-repeat;
} */
.pattern {
  background: linear-gradient(black 0%, black 100px, #3d3d3d 100px, #3d3d3d 260px, #1e1e1e 100%);
}

.curve::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  transform: translate(85%, 60%);
  background-color: #3d3d3d;
}

.curve {
  position: absolute;
  height: 100px;
  width: 100%;
  top: 0;
  text-align: center;
  /* overflow: hidden; */
}

.curve::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  background-color: black;
  transform: translate(-4%, 40%);
  /* z-index: -1; */
}
