.swiper {
  width: 80%;
  height: 100%;
  text-align: center;
  margin-bottom: 130px;

}
.carr{
    margin-left: 2px;
}

.swiper-slide {
  text-align: center;
  font-size: 16px;
  

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 0px;
  left: 0;
  width: 100%;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin-bottom: 31px;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,8px));
  height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,8px));
  display: inline-block;
  border-radius: 50%;
  background: #00d1ff;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, .2);
}
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #00d1ff;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: 10px;
  right: auto;
  color: #00d1ff;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: 3px;
  left: auto;
  color: #00d1ff;
}

@media (max-width:350px) {
  .swiper-slide {
   
    font-size: 16px;
    
    
}
}